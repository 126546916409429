.hero {
  background: "#FFF";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.firstHome {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  position: relative;
}

.firstHome::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  margin-top: 46px;
  margin-bottom: 46px;
  width: 2px;
  background-color: #ccc;
}

.heroContentContainer {
  flex: 1;
}

.signupFormContainer {
  flex: 1;
}

.heroContentContainer,
.signupFormContainer {
  padding-bottom: 10px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.hero_content h1 {
  font-size: 28px;
  margin-top: 12px;
  font-weight: 500;
}

.hero_content h1 span {
  font-weight: 700;
}

.hero h3 {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
}

.field-input,
.name-input {
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 16px;
  margin-top: 10px;
  border: 1px solid #979797;
  border-radius: 0px;
}

.field-input:focus,
.name-input:focus {
  outline: none;
  border: 1px solid #979797;
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}

.name-fields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.name-input {
  flex: 1;
  margin-right: 16px;
}

.name-input:last-child {
  margin-right: 0;
}

.signup {
  background: #fff;
  width: 476px;
  margin-top: 32px;
  text-align: left;
  margin-left: auto;
  padding: 20px 5% 20px 0px;
}

.hero_content {
  width: 100%;
  margin: 25px auto;
  padding-left: 5%;
}

#statusTitle {
  margin-bottom: 8px;
}

#status {
  margin-bottom: 8px;
}

.action-btn {
  background-color: #E63946;
  color: #fff !important;
  text-align: center;
  box-sizing: border-box;
  box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  display: inline-block;
  width: 100%;
  height: 48px;
  margin-top: 10px;
  margin-bottom: 12px;
  /* padding: 16px; */
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}


.signup-notice {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin: auto;
}

.hero a {
  color: #457B9D;
}

.result-chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.chip {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 0px;
  color: black;
  background-color: #F7F4F4;
  font-size: 12px;
  cursor: default;
  width: 100%;
  overflow: visible;
}

.copy-btn {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-btn img {
  height: 12px;
  width: 12px;
}

.chip span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 30px);
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 24px;
  text-align: center;
  padding: 20px;
}

.empty-state img,
.empty-state svg {
  margin-bottom: 10px;
}

@media screen and (max-width: 1100px) {
  .firstHome {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }


  .signup {
    width: 376px;
  }

  .hero_content {
    margin: auto;
    padding: 0 2%;
  }
}

.copy-feedback {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5D9CB8;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeInOut 3s linear;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  10%,
  90% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {

  .name-fields {
    flex-direction: column;
  }

  .name-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .heroContentContainer,
  .signupFormContainer {
    padding-bottom: 0px;
    width: 100%;
    flex: none;
  }

  .hero_content {
    padding: 0px;
  }

  .firstHome::before {
    display: none;
  }

  .hero_content {
    width: 95%;
    margin-bottom: 20px;
  }

  .signup {
    width: 95%;
    padding: 10px 5% 10px 0px;

  }
}

@media screen and (max-width: 550px) {

  .hero_content h1 {
    padding: 0px;
    font-size: 24px;
  }

  .signupFormContainer>*:last-child,
  .heroContentContainer>*:first-child {
    margin-bottom: 0;
    margin-top: 0;
  }

  /* .hero_content h1 {
    font-size: 42px;
    margin-top: 12px;
    font-weight: 500;
  } */

  .hero_content h2 {
    font-size: 16px;
  }

  .signup2,
  .signup {
    width: 100%;
    padding: 12px;
    border-radius: 0px;
  }

  .heroContentContainer,
  .signupFormContainer {
    padding-bottom: 0px;
    width: 100%;
    flex: none;
  }
}